import Ajax from "@/api/ajax";
export default {
  // 区号列表
  nOrderList(params) {
    return Ajax.post("/Home/nOrderList", params);
  },
  transportDetail(params) {
    return Ajax.post("/Home/nOrderDetails", params);
  },
  // 新增申报人
  addApplicant(params) {
    return Ajax.post("/Home/addUserDeclarations", params);
  },
  // 申报人列表 /Home/userDeclarationsList
  userDeclarationsList(params) {
    return Ajax.post("/Home/userDeclarationsList", params);
  },
  //删除申报人   /Home/deleteUserDeclarations
  deleteUserDeclarations(params) {
    return Ajax.post("/Home/deleteUserDeclarations", params);
  },
  // 可提交运单列表 /Home/packageOnShelf
  packageOnShelf(params) {
    return Ajax.post("/Home/packageOnShelf", params);
  },
  //提交申报
  submitDeclaration(params) {
    return Ajax.post("/Home/submitShipment", params);
  },
  // 预估申报人数量 /Home/getEstimatedDeclarants
  getEstimatedDeclarants(params) {
    return Ajax.post("/Home/getEstimatedDeclarants", params);
  },
  // 运单列表 /Home/waybillList
  waybillList(params) {
    return Ajax.post("/Home/waybillList", params);
  },
  // 运输方式
  waybillDetails(params) {
    return Ajax.post("/Home/waybillDetails", params);
  },
  // 运单支付
  payWaybill(params) {
    return Ajax.post("/Home/payWaybill", params);
  },
  // 收货
  receiveWaybill(params) {
    return Ajax.post("/Home/receiveWaybill", params);
  },
  // 订单数量
  orderCount(params) {
    return Ajax.post("/Home/nOrderCount", params);
  },
  // 我的包裹数量
  waybillCount(params) {
    return Ajax.post("/Home/waybillCount", params);
  },
  // 获取入库图片
  getStoredImage(params) {
    return Ajax.post("/Home/getStoredImage", params);
  },
  // 问题订单补款 /Home/handleProblemOrderPay
  handleProblemOrderPay(params) {
    return Ajax.post("/Home/handleProblemOrderPay", params);
  },
  // 查询认领包裹
  unownedPackage(params) {
    return Ajax.post("/Home/unownedPackage", params);
  },
  // 认领包裹
  packageClaim(params) {
    return Ajax.post("/Home/packageClaim", params);
  },
  // 认领记录
  userPackageClaimInfo(params) {
    return Ajax.post("/Home/userPackageClaimInfo", params);
  },
  // 取消包裹
  cancelShipment(params) {
    return Ajax.post("/Home/cancelShipment", params);
  },
};
