import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url
// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
} 
const indexRouterlist = [
  route('/goodsList', 'yahoojp/goodsList', 'goodsList', { title: '雅虎商品列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
]


export default {
  indexRouterlist: indexRouterlist
}
