import store from '../store';
import md5 from 'js-md5'
import api from '../api/request'
import domainGroups from '../../public/indexApiurl'

//获取小数点
function getkeep_decimals() {
  const keep_decimals = store.state.statedefrate === '' || store.state.statedefrate==null ? '2' : store.state.statedefrate.keep_decimals
  return keep_decimals;

}


/**
 * 字符串转化成浮点型并转换两位小数
 */
function strToFloat(data) {
  const count = parseFloat(data)
  let keep_decimals = getkeep_decimals();
  return count.toFixed(keep_decimals)
}
// 保留小数
// 四舍五入
function roundData(num, n) {
  return Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
}

function data_keep_decimals(data) {
  let keep_decimals = getkeep_decimals();
  if (typeof data === 'string') {
    if (data.indexOf('.') > -1) {
      return roundData(data, keep_decimals)
    } else {
      return data
    }
  } else {
    return roundData(data, keep_decimals)
  }
}

function moneyFormat(data) {
  var res = data.toString().replace(/\d+/, function (n) { // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ','
    })
  })
  return res
}



/**
 * 数组内数字排序
 */
function sort(arr) {
  for (var i = 0; i < arr.length - 1; i++) {
    for (var j = 0; j < arr.length - i - 1; j++) {
      if (arr[j] > arr[j + 1]) { // 相邻元素两两对比
        var hand = arr[j]
        arr[j] = arr[j + 1]
        arr[j + 1] = hand
      }
    }
  }
  return arr
}

// 按字母排序排序的函数

// 判断a数组中是否包含b数组
function isContained(aa, bb) {
  if (!(aa instanceof Array) || !(bb instanceof Array) || ((aa.length < bb.length))) {
    return false
  }
  for (var i = 0; i < bb.length; i++) {
    var flag = false
    for (var j = 0; j < aa.length; j++) {
      if (aa[j] === bb[i]) {
        flag = true
        break
      }
    }
    if (flag === false) {
      return flag
    }
  }
  return true
}
// 处理JSON
function getConfigData(str) {
  let result = ''
  if (typeof str === 'string' && str !== '') {
    if (str.indexOf('%') > -1) {
      result = JSON.parse(unescape(str))
    } else {
      result = JSON.parse(str)
    }
  } else {
    result = str
  }
  return result
}

// 查找回调 
function findValue(data, translate_arr,cengji) {
  if (typeof (data) === 'object') {
    for (const item in data) {
      if (typeof (data[item]) === 'string') {
        if (translate_arr && md5((data[item].trim())) in translate_arr) {
          data[item] = translate_arr[md5(data[item].trim())]
        }
      } else if ((typeof (data[item]) === 'object' && cengji=='') || (typeof (data[item]) == 'object' && cengji=='1' && item!='children')) {
        findValue(data[item], translate_arr,cengji)
      }
    }
  }
}
// 翻译数据替换
function replaceData(data, translate_arr,cengji='') {
  findValue(data, translate_arr,cengji)
  return data
}
// 从地址栏上获取参数

function getQueryStringArgs(url) {
  //取得查询字符串并去掉开头的问号
  var qs = ''
  if (url.length > 0 && url.indexOf('?') > -1) {
    qs = url.substring(1)
  } else {
    qs = url
  }
  var args = {},
    //取得每一项
    items = qs.length ? qs.split("&") : [],
    item = null,
    name = null,
    value = null,
    //在 for 循环中使用
    i = 0,
    len = items.length;
  //逐个将每一项添加到 args 对象中
  for (i = 0; i < len; i++) {
    item = items[i].split("=");
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args;
}
//根据不同端链接跳转处理
function Jumplink(links) {
  let Isjswxpay = store.state.isWeixin; //全局是否微信端变量
  let isSafari = store.state.isSafari; //是否safari浏览器
  if (isSafari) {
    window.location.replace(links, "_blank");
  } else if (Isjswxpay) {
    location.href = links;
  } else {
    window.open(links, "_blank");
  }
}
//上传图片
//获取图片地址
function onFileChange(e) {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
 
  var formData = new FormData();
  formData.append("file", files[0]);
  api.public.uploadimgs(formData).then((res) => {
    if (res.status) {
      store.commit('setuploadurl',res.data)
    }
  });
}

// 根据不同的域名匹配请求域名使用
function getApiUrl(localDomain) {
  // console.log(domainGroups);
  // console.log(localDomain);
  const defaultApiUrl = store.state.apiurl; // 默认服务器
  for (const group in domainGroups) {
    if (domainGroups[group].includes(localDomain)) {
      switch (group) {
        case 'other':
          return store.state.otherapiurl;
        case 'xg':
          return store.state.xgapiurl;
        case 'xgTwo':
          return store.state.xgTwoapiurl;
        case 'xgThree':
          return store.state.xgThreeapiurl;
        case 'gobuy':
          return store.state.gobuyapiurl;
        case 'beijixing':
          return store.state.beijixingapiurl;
        case 'rijitong':
          return store.state.rijitongApiurl;
        default:
          return defaultApiUrl;
      }
    }
  }
  return defaultApiUrl; // 如果没有匹配到任何域名，返回默认值
}


  export default {
    strToFloat,
    sort,
    isContained,
    data_keep_decimals,
    moneyFormat,
    getConfigData,
    replaceData,
    roundData,
    getQueryStringArgs,
    Jumplink,
    onFileChange,
    getApiUrl
  }
