import Ajax from "@/api/ajax";
export default{
    //获取物流信息
    getlogisticsTrack(params) {
        return Ajax.post("/Home/logisticsTrack", params);
    },
    //余额提现
    withdrawBalance(params) {
        return Ajax.post("/Home/withdrawBalance", params);
    },
    

}