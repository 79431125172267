import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url

// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
}
const indexRouterlist = [
  route('/', 'yahoojp/Home', '', { title: '首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/order-pack-pay', 'Orders/OrderpackPay', 'OrderpackPay', { title: '订单打包', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/order-pay', 'Orders/orederPay', 'orederPay', { title: '支付订单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/fill-shop', 'fillshop/index', '', { title: '填单购物', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
]
const personRouterlist = [
  route('/order-list', 'Orders/OrderList', 'OrderList', { title: '代购订单列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/top-up', 'user/topUp', '', { title: '账户充值', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/add-address', 'address/AddAddress', '', { title: '添加地址', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-pack-details', 'Orders/OrderPackDetails', 'OrderPackDetails', { title: '转运包裹详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/my-withdraw', 'profile/mywithdraw', '', { title: '我的提现', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-details', 'Orders/OrderDetails', 'OrderDetails', { title: '代购订单详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
]
export default {
  indexRouterlist:indexRouterlist,
  personRouterlist:personRouterlist,
}
