import Ajax from "@/api/ajax";
export default{
    //获取区域码
    getPhoneCode(params) {
        return Ajax.post("/Home/phoneCode", params);
    },
    //预报商品分类
    getForecastCategory(params) {
        return Ajax.post("/Home/forecastCategory", params);
    },
    //自提点列表
    getPickupList(params) {
        return Ajax.post("/Home/get_pickup_list", params);
    },
    //地址接口
    getRegions(params) {
        return Ajax.post("/Home/Regions", params);
    },
    //根据国家返回 派送公司
    getPickupCompany(params) {
        return Ajax.post("/Home/get_pickup_company", params);
    },
    //line登录返回链接
    lineRedirect(params) {
        return Ajax.post("/Home/line_redirect", params);
    },
    //line登录后
    lineCallback(params) {
        return Ajax.post("/Home/line_callback", params);
    },
    // 微信登录
    wechatLogin(params) {
        return Ajax.post("/Home/Thirdpartylogincode/index", params);
    },
    // 微信登录后操作
    wechatCallback(params) {
        return Ajax.post("/Home/wechat_callback", params);
    }
}