import Ajax from "./ajax";
import store from "@/store/index";
const theme = store.state.theme;
const router_domain_url = store.state.router_domain_url;
let requestOrder = {};
try {
  requestOrder = require(`@/views/theme/${theme}/api/index.js`).default;
} catch (e) {
  try {
    requestOrder =
      require(`@/views/customization/${router_domain_url}/api/index.js`).default;
  } catch (e) {
    requestOrder = {};
  }
}
const Request = {
  // 公共
  public: {
    articleList(params) {
      return Ajax.post("/Home/articleList", params);
    },
    // 文章内容
    articleItem(params) {
      return Ajax.post("/Home/articleItem", params);
    },
    // 网站基本配置
    webMsg(params) {
      return Ajax.post("/Home/webMsg", params);
    },
    // 建站基础信息
    getbaseMsg(params) {
      return Ajax.post("/Home/get_base_msg", params);
    },
    sendcode(params) {
      // 发送验证码
      return Ajax.post("/Home/sendPhoneCode", params);
    },
    sendEmailcode(params) {
      // 发送邮件验证码
      return Ajax.post("/Home/sendEmailCode", params);
    },
    estimateMoney(params) {
      // 费用估算
      return Ajax.post("/Home/estimateMoney", params);
    },
    hotKeyword(params) {
      // 推荐关键词
      return Ajax.post("/Home/hotKeyword", params);
    },
    buyFeeBaoYue(params) {
      // 购买转账手续费包月
      return Ajax.post("/Home/buyTransfeeBaoYue", params);
    },
    // 获取语言包json
    getLangPack(params) {
      return Ajax.post("/Home/get_lang_pack", params);
    },
    // 获取网站导航
    getWebNavtion(params) {
      return Ajax.post("/Home/get_web_navigation", params);
    },
    // 获取网站搜索导航
    getWebMsg(params) {
      return Ajax.post("/Home/get_web_msg", params);
    },
    // 前台动态翻译
    translate_arr(params) {
      return Ajax.post("/Home/translate_arr", params);
    },
    // 客服图标位置接口
    customer_service(params) {
      return Ajax.post("/Home/customer_service", params);
    },
    // 区号列表
    smsMsg(params) {
      return Ajax.post("/Home/smsMsg", params);
    },
  },
  // 用户注册登陆
  signIn: {
    login(params) {
      return Ajax.post("/Home/login", params);
    },
    register(params) {
      return Ajax.post("/Home/register", params);
    },
    // 国家列表
    countryList(params) {
      return Ajax.post("/Home/countryList", params);
    },
    findPassword(params) {
      return Ajax.post("/Home/findPassword", params);
    },
    // 编辑用户信息
    editUserInfo(params) {
      return Ajax.post("/Home/edit_user_info", params);
    },
    // 注销账户信息
    logoffUser(params) {
      return Ajax.post("/Home/logoff_user", params);
    },
    // 苹果登录
    loginApple(params) {
      return Ajax.post("/Home/appleLogin", params);
    },
    // 谷歌登录
    loginGoogle(params) {
      return Ajax.post("/Home/google_login", params);
    },
  },
  // 首页
  home: {
    // 图片搜索
    getImgKeyword(params) {
      return Ajax.post("/Home/imageTransformKeyword", params);
    },
    // 竞拍分类
    jpCategory(params) {
      return Ajax.post("/Home/yahoojp_category", params);
    },
    // 煤炉分类
    merCategory(params) {
      return Ajax.post("/Home/mercariCategoryMsg", params);
    },
    // 煤炉分类管理
    classification(params) {
      return Ajax.post("/Home/classification", params);
    },
    // 竞拍首页搜索
    listSearch(params) {
      return Ajax.post("/Home/yahoojp_list_search", params);
    },
    // 竞拍子分类获取
    yahoojpCategory(params) {
      return Ajax.post("/Home/yahoojpCategory", params);
    },
    listCategory(params) {
      return Ajax.post("/Home/yahoojp_list_category", params);
    },
    listSeller(params) {
      return Ajax.post("/Home/yahoojp_list_seller", params);
    },
    banner(params) {
      return Ajax.post("/Home/rotation", params);
    },
    hotKey(params) {
      return Ajax.post("/Home/hotKey", params);
    },
    module(params) {
      return Ajax.post("/Home/module", params);
    },
    // 商品详情
    detail(params) {
      // 商品详情
      return Ajax.post("/Home/yahoojp_goods_item", params);
    },
    // 获取竞拍支付限额
    getPayMone(params) {
      return Ajax.post("/Home/checkUserMoney", params);
    },
    record(params) {
      // 出价记录
      return Ajax.post("/Home/yahoojp_bidRecord", params);
    },
    collect(params) {
      // 商品收藏
      return Ajax.post("/Home/collection", params);
    },
    goBid(params) {
      // 商品竞拍出价 一口价
      return Ajax.post("/Home/yahoojp_bid", params);
    },
    goToAgainBid(params) {
      // 商品竞拍出价 一口价
      return Ajax.post("/Home/yahoojp_bid_price", params);
    },
    goKeepBid(params) {
      // 商品竞拍出价  预约出价
      return Ajax.post("/Home/yahoojp_keep_bid", params);
    },
    cancelKeepBid(params) {
      // 商品竞拍出价  取消预约出价
      return Ajax.post("/Home/cancel_keep_jporder", params);
    },
    listCategory(params) {
      return Ajax.post("/Home/yahoojp_list_category", params);
    },

    // 煤炉
    mercariGoods(params) {
      return Ajax.post("/Home/mercariRecommendGoods", params);
    },
    mercariListSearch(params) {
      return Ajax.post("/Home/mercari_goods_search", params);
    },
    mercariListSeller(params) {
      return Ajax.post("/Home/mercari_goods_seller", params);
    },
    // 煤炉自动采购
    mercariAutopay(params) {
      return Ajax.post("/Home/mercari_go_buy", params);
    },
    // 查询第三方支付 是否支付成功
    checkEzgopayStatus(params) {
      return Ajax.post("/Home/check_pay_status", params);
    },
    // 商品详情
    mercaridetail(params) {
      // 商品详情
      return Ajax.post("/Home/mercari_goods_item", params);
    },
    mercariListCategory(params) {
      // 相似商品
      return Ajax.post("/Home/mercari_goods_relevant", params);
    },
    // 商品议价
    mercariBargain(params) {
      // 相似商品
      return Ajax.post("/Home/create_bargain_order", params);
    },
    // 卖家商品列表
    mercariSeller(params) {
      // 相似商品
      return Ajax.post("/Home/mercari_goods_seller", params);
    },
    // 卖家商品列表(官方)
    mercariSearchGoods(params) {
      return Ajax.post("/Home/mercariSearchGoods", params);
    },
    // 卖家信息(官方)
    mercariShopMessage(params) {
      return Ajax.post("/Home/mercariShopMessage", params);
    },
    // 卖家信息(官方)
    mercariGoodsMessage(params) {
      return Ajax.post("/Home/mercariGoodsMessage", params);
    },

    // 乐天中古
    frilCategory(params) {
      // 分类列表
      return Ajax.post("/Home/frilCategory", params);
    },
    frilGoodsSearch(params) {
      // 关键词搜索
      return Ajax.post("/Home/frilGoodsSearch", params);
    },
    frilGoodsCategory(params) {
      // 分类商品列表
      return Ajax.post("/Home/frilGoodsCategory", params);
    },
    frilbrandList(params) {
      // 品牌商品列表
      return Ajax.post("/Home/frilbrandList", params);
    },
    frilGoodsItem(params) {
      // 商品详情
      return Ajax.post("/Home/frilGoodsItem", params);
    },
    frilShopItem(params) {
      // 卖家列表数据
      return Ajax.post("/Home/frilShop", params);
    },
    // wakuwaku出价的价格
    getGoodsJpyToRmb(params) {
      return Ajax.post("/Home/goodsJpyToRmb", params);
    },

    // 任空间
    // 首页banner
    getHomeBannerListy(params) {
      return Ajax.post("/Home/get_home_set_list", params);
    },
    // 淘淘话题
    getTopicList(params) {
      return Ajax.post("/Home/get_topic_list", params);
    },
  },
  // paypay频道数据接口
  paypay: {
    paypayList(params) {
      // 商品列表
      return Ajax.post("/Home/paypayshopGoodsList", params);
    },
    paypayGoodsItem(params) {
      // 商品详情
      return Ajax.post("/Home/paypayshopGoodsItem", params);
    },
    paypayCategory(params) {
      // 商品分类
      return Ajax.post("/Home/paypayshopCategory", params);
    },
    paypayOtherGoods(params) {
      // 推荐商品
      return Ajax.post("/Home/paypayshopOtherGoods", params);
    },
    paypayshopSellerList(params) {
      // 卖家列表
      return Ajax.post("/Home/paypayshopSellerList", params);
    },
  },

  // Rakuten频道数据接口
  rakuten: {
    rakutenList(params) {
      // 商品列表
      return Ajax.post("/Home/rakutenGoodsSearch", params);
    },
    rakutenGoodsItem(params) {
      // 商品详情
      return Ajax.post("/Home/rakutenGoodsItem", params);
    },
    rakutenCategory(params) {
      // 商品分类
      return Ajax.post("/Home/rakutenCategory", params);
    },
  },
  // zozotown频道数据接口
  zozotown: {
    zozoHome(params) {
      // 首页
      return Ajax.post("/Home/zozoHomeData", params);
    },
    zozoList(params) {
      // 列表页
      return Ajax.post("/Home/zozoGoodsList", params);
    },
    zozoGoodsItem(params) {
      // 商品详情
      return Ajax.post("/Home/zozoGoodsItem", params);
    },
    zozoOtherGoods(params) {
      // 详情关联商品
      return Ajax.post("/Home/zozoOtherGoods", params);
    },
    zozoGoodsSize(params) {
      // 商品属性
      return Ajax.post("/Home/zozoGoodsSize", params);
    },
    zozoShopList(params) {
      // 店铺列表
      return Ajax.post("/Home/zozoShopList", params);
    },
    zozoCategory(params) {
      // zozo分类数据
      return Ajax.post("/Home/zozoCategory", params);
    },
  },
  // yahooShop 雅虎直购
  yahooShop: {
    yahooSearch(params) {
      // yahooShop搜索
      return Ajax.post("/Home/yahooshop_goods_search", params);
    },
    yahooShopCategory(params) {
      // yahooShop分类数据
      return Ajax.post("/Home/yahooshop_category", params);
    },
    yahooGoodsItem(params) {
      // yahooShop 详情
      return Ajax.post("/Home/yahooshop_goods_item", params);
    },
  },
  // mikihouse
  mikihouse: {
    mikihouseSearch(params) {
      // mikihouseSearch 搜索
      return Ajax.post("/Home/mikihouse_search", params);
    },
    mikihouseCategory(params) {
      // mikihouseCategory 分类数据
      return Ajax.post("/Home/mikihouse_category", params);
    },
    mikihouseCategoryGoods(params) {
      // mikihouseCategoryGoods 分类商品列表
      return Ajax.post("/Home/mikihouse_category_goods", params);
    },
    mikihouseGoodsItem(params) {
      // mikihouseGoodsItem 商品详情
      return Ajax.post("/Home/mikihouse_goods_item", params);
    },
    mikihouseOtherGoods(params) {
      // mikihouseOtherGoods 商品关联商品
      return Ajax.post("/Home/mikihouse_other_goods", params);
    },
  },
  // 购物车
  cart: {
    createOrder(params) {
      return Ajax.post("/Home/create_order", params);
    },
    add(params) {
      return Ajax.post("/Home/add_cart", params);
    },
    list(params) {
      return Ajax.post("/Home/my_cart", params);
    },

    del(params) {
      return Ajax.post("/Home/cart_delete", params);
    },
  },
  // 留言
  leaveAMessage: {
    // 留言标签列表
    leaveAMessageList(params) {
      return Ajax.post("/Home/get_goods_message_label_list", params);
    },
    // 用户提交留言
    addLeaveAMessage(params) {
      return Ajax.post("/Home/add_goods_message", params);
    },
    // 获取商品的留言列表
    getLeaveAMessage(params) {
      return Ajax.post("/Home/get_goods_message_list", params);
    },
    // 获取商品留言列表详情
    getLeaveAMessageDetail(params) {
      return Ajax.post("/Home/get_goods_message_detail", params);
    },
  },
  // 订单
  order: {
    // 雅虎竞拍订单
    jpOrder(params) {
      return Ajax.post("/Home/jpOrders", params);
    },
    // 预约出价订单
    keepOrder(params) {
      return Ajax.post("/Home/keepOrders", params);
    },
    // 议价订单
    bargainOrder(params) {
      return Ajax.post("/Home/bargainOrders", params);
    },
    // 代购订单
    listOrder(params) {
      return Ajax.post("/Home/orderList", params);
    },
    // 取消未支付订单
    cancelOrder(params) {
      return Ajax.post("/Home/cancelOrder", params);
    },
    // 获取购物车数据
    confirm(params) {
      return Ajax.post("/Home/cart_list_ids", params);
    },
    // 勾选商品id获取商品信息
    orderGoodPay(params) {
      return Ajax.post("/Home/orderGoodsmsg", params);
    },
    // 勾选运输方式计算预估运费
    getFreight(params) {
      return Ajax.post("/Home/getFreight", params);
    },
    // 创建订单
    create(params) {
      return Ajax.post("/Home/subimt_cart", params);
    },
    // 支付订单
    payOrder(params) {
      return Ajax.post("/Home/payOrder", params);
    },
    // 订单详情
    orderDetail(params) {
      return Ajax.post("/Home/orderDetail", params);
    },
    // 更新订单价格
    refreshOrder(params) {
      return Ajax.post("/Home/batchJpOrder", params);
    },
    // 更新订单价格-收藏
    refreshOrderCollection(params) {
      return Ajax.post("/Home/batchUpCollection", params);
    },
    // 更新订单价格-预约未出价
    refreshOrderKeepOrder(params) {
      return Ajax.post("/Home/batchKeepOrder", params);
    },
  },
  // 地址
  address: {
    add(params) {
      return Ajax.post("/Home/create_address", params);
    },
    list(params) {
      return Ajax.post("/Home/address_list", params);
    },
    del(params) {
      return Ajax.post("/Home/address_delete", params);
    },
  },
  // 保证金
  bondMoney: {
    bondList(params) {
      return Ajax.post("/Home/bondList", params);
    },
    downUpBond(params) {
      return Ajax.post("/Home/downUpBondMoney", params);
    },
  },
  // 包裹列表
  package: {
    //香港本地配送方式
      getLocalLogistics(params) {
        return Ajax.post("/Home/getLocalLogistics", params);
    },
    // 运输方式
    tranWay(params) {
      return Ajax.post("/Home/transportWay", params);
    },
    // 勾选包裹ID 获取包裹支付信息
    goodsPack(params) {
      return Ajax.post("/Home/goodsMsgForPackIds", params);
    },
    // 获取增值服务
    service(params) {
      return Ajax.post("/Home/serviceWay", params);
    },
    // 生成包裹
    createPack(params) {
      return Ajax.post("/Home/createPack", params);
    },
    // 包裹列表
    packList(params) {
      return Ajax.post("/Home/packList", params);
    },
    // 支付包裹费用
    payPack(params) {
      return Ajax.post("/Home/payPack", params);
    },
    // 确认收货
    receipt(params) {
      return Ajax.post("/Home/receipt", params);
    },
    // 包裹订单详情
    packDetails(params) {
      return Ajax.post("/Home/packDetails", params);
    },
    // 包裹运输
    getTrackInfo(params) {
      return Ajax.post("/Home/getTrackInfo", params);
    },
  },

  // 用户类
  user: {
    deleteOrder(params) {
      return Ajax.post("/Home/delete_order", params);
    },
    orderNumber(params) {
      return Ajax.post("/Home/orderNumbers", params);
    },
    userInfo(params) {
      return Ajax.post("/Home/user_info", params);
    },
    // 商品收藏
    collectionList(params) {
      return Ajax.post("/Home/collectionList", params);
    },
    // 账单明细
    payRecord(params) {
      return Ajax.post("/Home/payRecord", params);
    },
    // 收款信息
    paymentMsg(params) {
      return Ajax.post("/Home/paymentMsg", params);
    },
    // 线下充值上传图片
    upImage(params) {
      return Ajax.post("/Home/upImage", params);
    },
    // 线下充值提交
    offRecharge(params) {
      return Ajax.post("/Home/offRecharge", params);
    },
    // 线上充值提交
    onlineRecharge(params) {
      return Ajax.post("/Home/onlineRecharge", params);
    },
    // 获取收款账号类型
    getColectionAccountType(params) {
      return Ajax.post("/Home/get_colection_account_type", params);
    },
    // 获取收款账号列表
    getColectionAccountList(params) {
      return Ajax.post("/Home/get_colection_account_list", params);
    },
    // 提现手续费
    cash_out_serviceFee(params) {
      return Ajax.post("/Home/cash_out_serviceFee", params);
    },
    // 提现申请提交
    cashOut(params) {
      return Ajax.post("/Home/cashOut", params);
    },
    // 提现列表
    cash_out_list(params) {
      return Ajax.post("/Home/cash_out_list", params);
    },
    // 多货币语言列表
    webExchangeRate(params) {
      return Ajax.post("/Home/webExchangeRate", params);
    },
  },
  // AUCNET会场接口
  AUCNET: {
    catelist1(params) {
      // 一级分类列表
      return Ajax.post("/Home/aucCategory", params);
    },
    catelist2(params) {
      // 二三级级分类列表
      return Ajax.post("/Home/subClass", params);
    },
    goodlist(params) {
      return Ajax.post("/Home/aucGoodsListA", params);
    },
    goodDetail(params) {
      return Ajax.post("/Home/aucGoodsItemA", params);
    },
    aucBidA(params) {
      // 旧接口
      // 周一场出价
      return Ajax.post("/Home/aucBidA", params);
    },
    aucGoodsBid(params) {
      // 新接口
      return Ajax.post("/Home/aucGoodsBid", params);
    },
    goodlistB(params) {
      return Ajax.post("/Home/aucGoodsListB", params);
    },
    goodDetailB(params) {
      return Ajax.post("/Home/aucGoodsItem", params);
    },
    aucBidB(params) {
      // 周二场出价
      return Ajax.post("/Home/aucBidB", params);
    },
    // 订单列表
    orderlist(params) {
      return Ajax.post("/Home/aucOrders", params);
    },
    // 保证金信息
    bondMsg(params) {
      return Ajax.post("/Home/aucBondMoney", params);
    },
    // 保证金充值
    bondRecharge(params) {
      return Ajax.post("/Home/rechargeBondMoney", params);
    },
    // 保证金提现
    bondWithdrawal(params) {
      return Ajax.post("/Home/bondWithdrawal", params);
    },
    // 保证金提现2(周总用)
    withdrawBondMoney(params) {
      return Ajax.post("/Home/withdrawBondMoney", params);
    },
    // 保证金操作记录
    bondRecored(params) {
      return Ajax.post("/Home/bondRecored", params);
    },
    // 保证金出价金额
    bidMoneyMsg(params) {
      return Ajax.post("/Admin/bidMoneyMsg", params);
    },
    // 充值保证金金额转换
    rechargeChange(params) {
      return Ajax.post("/Home/rechargeChange", params);
    },

    // 订单数量
    aucOrderNumbers(params) {
      return Ajax.post("/Home/aucOrderNumbers", params);
    },
    // 添加收藏
    collectionadd(params) {
      return Ajax.post("/Home/aucCollection", params);
    },
    // 取消收藏
    collectioncancel(params) {
      return Ajax.post("/Home/aucCancelCollection", params);
    },
    // 收藏列表
    collectionlist(params) {
      return Ajax.post("/Home/aucCollectionList", params);
    },
    // 出价金额
    writeBidMoney(params) {
      return Ajax.post("/Home/aucWriteBidMoney", params);
    },

    // 批量出价
    aucBatchBid(params) {
      return Ajax.post("/Home/aucBatchBid", params);
    },
    // 批量更新订单信息
    aucUpOrderMsg(params) {
      return Ajax.post("/Home/upOrderMsg", params);
    },
  },
  // 填单购物
  fillshop: {
    add(params) {
      // 添加
      return Ajax.post("/Home/fill_shop", params);
    },
    catelist(params) {
      // 获取网站列表
      return Ajax.post("/Home/get_fill_shop_category", params);
    },
  },
  // 转运
  forecastSubmit: {
    // 无主订单列表
    forecastMsg(params) {
      return Ajax.post("/Home/forecastMsg", params);
    },
    // 转运信息提交
    addForecastSubmit(params) {
      return Ajax.post("/Home/forecastSubmit", params);
    },
    submitTransport(params) {
      return Ajax.post("/Home/submitTransport", params);
    },
  },
  // 包裹认领
  bags: {
    // 未认领的包裹
    noUserOrders(params) {
      return Ajax.post("/Home/noUserOrders", params);
    },
    // 点击认领包裹
    bindUser(params) {
      return Ajax.post("/Home/bindUser", params);
    },
  },
  // 站内信
  message: {
    // 发送站内信
    send(params) {
      return Ajax.post("/Home/add_message", params);
    },
    list(params) {
      return Ajax.post("/Home/get_message_list", params);
    },
    detail(params) {
      return Ajax.post("/Home/get_message_detail", params);
    },
  },
  // 身份证列表
  cardNumber: {
    cardList(params) {
      // 身份证列表
      return Ajax.post("/Home/get_id_card_list", params);
    },
    upLoad(params) {
      // 上传身份证图片
      return Ajax.post("/Home/upload_id_card", params);
    },
    submitCard(params) {
      // 提交身份证信息
      return Ajax.post("/Home/submit_id_card", params);
    },
    delCard(params) {
      // 删除身份证
      return Ajax.post("/Home/del_id_card", params);
    },
  },
  // 亚马逊
  amazon: {
    amazon_goods_search(params) {
      // 亚马逊搜索
      return Ajax.post("/Home/amazonGoodsList", params);
    },
    amazon_goods_item(params) {
      // 商品详情
      return Ajax.post("/Home/amazon_goods_item", params);
    },
  },
  // 骏河屋
  suruga: {
    suruga_goods_list(params) {
      // 骏河屋关键词搜索
      return Ajax.post("/Home/suruga_goods_list", params);
    },
    suruga_category(params) {
      // 骏河屋分类
      return Ajax.post("/Home/suruga_category", params);
    },
    suruga_goods_item(params) {
      // 商品详情
      return Ajax.post("/Home/suruga_goods_item", params);
    },
    suruga_other_goods(params) {
      // 其他商品
      return Ajax.post("/Home/suruga_other_goods", params);
    },
  },
  // 自营商城
  selfShopping: {
    mallCategory(params) {
      // 商品分类
      return Ajax.post("/Home/mallCategory", params);
    },
    mallGoodsList(params) {
      // 商品列表
      return Ajax.post("/Home/mallGoodsList", params);
    },
    mallGoodsItem(params) {
      // 商品详情
      return Ajax.post("/Home/mallGoodsItem", params);
    },
    mallSimilarGoods(params) {
      // 同类型商品
      return Ajax.post("/Home/mallSimilarGoods", params);
    },
  },
  coupon: {
    unreceivedCoupon(params) {
      // 是否有新的优惠券未领取
      return Ajax.post("/Home/get_unreceived_coupon", params);
    },
    couponList(params) {
      // 优惠券列表
      return Ajax.post("/Home/get_coupon_user_list", params);
    },
    exchangeCoupon(params) {
      // 兑换优惠券
      return Ajax.post("/Home/exchange_coupon", params);
    },
    receiveCoupon(params) {
      // 领取优惠券
      return Ajax.post("/Home/receive_coupon", params);
    },
  },
  // 推广返利
  share: {
    //返利记录
    getrebateRecord(params) {
      return Ajax.post("/Home/rebate_record", params);
    },
    //我的推广
    getMyPromotion(params) {
      return Ajax.post("/Home/my_promotion", params);
    },
    //返利提现
    rebateCash(params) {
      return Ajax.post("/Home/rebate_cash", params);
    },
  },
  ECO: {
    // 分类
    category(params) {
      return Ajax.post("/Home/ecoCategory", params);
    },
    // 商品列表
    goodsEcoList(params) {
      return Ajax.post("/Home/ecoGoodsList", params);
    },
    // 商品详情
    goodsDetail(params) {
      return Ajax.post("/Home/ecoGoodsDetail", params);
    },
    // 预约出价 /Home/ecoAddPreBid
    addPreBid(params) {
      return Ajax.post("/Home/ecoAddPreBid", params);
    },
    // 订单列表 
    ecoBidRecord(params){
      return Ajax.post("/Home/ecoBidRecord", params);
    }
  },
  // 每个网站单独的接口，在每个文件夹下的api/index中编写
  ...requestOrder,
};
export default Request;
