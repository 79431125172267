import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url

// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
}
const indexRouterlist = [
  route('/order-pack-pay', 'Orders/OrderpackPay', 'OrderpackPay', { title: '订单打包', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/advance-order', 'home/advanceorder', 'advance-order', { title: '预报下单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/for-pack-pay', 'Orders/ForPackPay', 'ForPackPay', { title: '支付国际运费', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
]
const publicRouterlist = [
    route('/sign-up', 'public/SignUp', 'signUp', { title: '注册', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
    route('/sign-in', 'public/SignIn', 'signIn', { title: '登录', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
]
// 个人中心子级
const personRouterlist = [
  route('/order-list', 'Orders/OrderList', 'OrderList', { title: '代购订单列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-list-pack', 'Orders/OrderListPack', 'OrderListPack', { title: '包裹列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-pack', 'Orders/Orderpack', 'Orderpack', { title: '订单待打包', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/transport-detail', 'Orders/transportDetail', 'transportDetail', { title: '转运订单详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-pack-details', 'Orders/OrderPackDetails', 'OrderPackDetails', { title: '转运包裹详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-details', 'Orders/OrderDetails', 'OrderDetails', { title: '代购订单详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/parcel-Claim', 'Orders/parcelClaim', 'parcelClaim', { title: '包裹认领', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  //申报人
  route('/applicant', 'applicant/index', 'applicant', { title: '申报人列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/claim-list', 'Orders/claimList', 'claimList', { title: '认领列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
]
export default {
  publicRouterlist: publicRouterlist,
  indexRouterlist: indexRouterlist,
  personRouterlist: personRouterlist,
}
